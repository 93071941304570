<template>
    <div class="events px-3">
      <div class="my-container">
        <img :src="eventStyle.logo" alt="" srcset="" class="main-logo">
        <div class="content">
          <div class="inputs">
            <div>
              <p>الاسم</p>
              <input type="text" name="" v-model="reservationDetails.user_name" placeholder="اسم">
            </div>
            <div  class="my-3">
              <p>رقم الجوال</p>
              <input type="number" name="" v-model="reservationDetails.mobile" placeholder="رقم الجوال">
            </div>
            <div class="my-3">
              <p>تاريخ الحجز المتاح</p>
              <select name="" id="" class="selector" v-model="reservationDetails.date_id">
                <option v-for="(event ,index) in eventDetails" :value="event.id" :key="index">{{event.date}} | {{event.time}}</option>
              </select>
            </div>
            <div  class="my-3">
              <p>عدد المقاعد المراد حجزها</p>
              <input type="number" name="" v-model="reservationDetails.number_of_chairs" placeholder="عدد المقاعد المراد حجزها">
            </div>
            <div  class="my-3">
              <p>طريقة الدفع</p>

              <div class="dropdown">
                <div @click="isActive = !isActive" class="dropdown__show">
                  <div>
                    <img :src="selectedPaymentType.image" alt="" srcset="">
                    <p>{{selectedPaymentType.name_ar}}</p>
                  </div>
                  <img src="../../../../../public/assets/images/website/events/down-arrow.png" :class="[isActive ? 'rotate' : '']" alt="" srcset="">
                </div>
                <div class="dropdown__menu" :class="[isActive ? 'show' : '']">
                  <div v-for="(type,index) in paymentTypes" :key="index"  @click="selectedPaymentType = type,isActive = false ,reservationDetails.payment_type = type.name" :class="[selectedPaymentType == type ? 'selected' : '']">
                    <img :src="type.image" alt="" srcset="">
                    <p>{{type.name_ar}}</p>
                  </div>
                </div>
              </div>

           
              <!-- <div class="d-flex align-items-center justify-content-between flex-wrap">

                <div class="d-flex align-items-center">
                    <input type="radio" id="SYRIATEL" name="fav_language" value="SYRIATEL" class="mr-2" v-model="reservationDetails.payment_type">
                    <label for="SYRIATEL">
                        <img src="../../../../../public/assets/images/website/mawlid-alhuda/syriatel-cash.png" alt="" srcset="">
                    </label>
                </div>

                <div class="d-flex align-items-center">
                    <input type="radio" id="MTN" name="fav_language" value="MTN" class="mr-2" v-model="reservationDetails.payment_type">
                    <label for="MTN">
                        <img src="../../../../../public/assets/images/website/mawlid-alhuda/mtn-cash.png" alt="" srcset="">
                    </label>
                </div>
                
                <div class="d-flex align-items-center">
                    <input type="radio" id="FATORA" name="fav_language" value="FATORA" class="mr-2" v-model="reservationDetails.payment_type">
                    <label for="FATORA">
                        <img src="../../../../../public/assets/images/website/mawlid-alhuda/mtn-cash.png" alt="" srcset="">
                    </label>
                </div>

             </div> -->

            </div>
          </div>
          <div class="price">
            <p>سعر البطاقة الواحدة {{numberWithCommas(ticketPrice)}} ل.س</p>
          </div>
          <div class="buttons mt-5">
            <ButtonWithLoader @click.native="inputValidation()" :disabled="btnLoader" :showLoader="btnLoader" :color="eventStyle.btnColor" text="التالي"/>
          </div>
        </div>
      </div>
    </div>
  </template>
<script>
import toasted from '../../../shared/utilities/toasted'
import eventsRepository from "../../repositories/eventsRepository";
import ButtonWithLoader from '../../website-components/events/ButtonWithLoader.vue';
export default {
    name: 'EventPage',
    metaInfo: {
      title: 'الحفلات',
    },
    components: { ButtonWithLoader },
    data: () => ({
        selectedPaymentType: {
          name: null,
          name_ar: null,
          image: null
        },
        paymentTypes:[
          {
            name:'SYRIATEL',
            name_ar:'سيريتل كاش',
            image: require('../../../../../public/assets/images/website/events/SYRIATEL.jpg')
          },
          {
            name:'MTN',
            name_ar:'كاش موبايل',
            image: require('../../../../../public/assets/images/website/events/MTN.jpg')
          },
          {
            name:'FATORA',
            name_ar:'فاتورة',
            image: require('../../../../../public/assets/images/website/events/FATORA.jpg')
          },
        ],
        isActive: false,
        eventID: null,
        // eventID: 26,
        highestValue: 550000,
        btnLoader: false,
        reservationDetails:{
          user_name: null,
          mobile: null,
          date_id: null,
          number_of_chairs: null,
          payment_type: null
        },
        ticketPrice: null,
        eventStyle: {
          logo: null,
          btnColor: null
        },
        eventDetails: null,
    }),
    methods: {
        onResizeScroll() {
          this.isActive = false
        },
        numberWithCommas(x) {
            return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        inputValidation() {
            if (Object.values(this.reservationDetails).some(x => x == null || x == '') || this.reservationDetails.number_of_chairs < 1) {
                toasted.failed('الرجاء إدخال المعلومات بالطريقة الصحيحة');
            } else if(this.reservationDetails.mobile.toString().length != 10 || this.reservationDetails.mobile.toString().slice(0, 2) != "09"){
                toasted.failed('الرجاء إدخال رقم الموبايل بالطريقة الصحيحة');
            } else if((this.reservationDetails.numOfTicket * this.ticketPrice ) > this.highestValue) {
                toasted.failed('عدد المقاعد المراد حجزها كبير');
            } else {
                this.bookingChairs()
            }
        },
        async getEventId(){
          let eventID = await eventsRepository.getEventId()
          this.eventID = eventID.value
          await this.getEventDetails(this.eventID);
        },
        async getEventDetails(id) {
          const event = await eventsRepository.getEventDetails(id)
          let eventStyle = {
            logo: event.logo,
            btnColor: event.color
          }
          this.eventStyle = eventStyle
          localStorage.setItem('eventStyle',JSON.stringify(eventStyle))
          this.ticketPrice = event.chair_price
          this.eventDetails = event.events.map(elem => (
            {
              id: elem.id,
              date: elem.date,
              name: elem.name,
              time: `الساعة ${elem.from_time}` 
            } 
          ))
        },
        async bookingChairs() {
          localStorage.removeItem('ReservationDetails')
          this.btnLoader = true
          let payload = {
            event_id: this.reservationDetails.date_id,
            user_name: this.reservationDetails.user_name,
            mobile: this.reservationDetails.mobile,
            number_of_chairs: this.reservationDetails.number_of_chairs,
            payment_type: this.reservationDetails.payment_type
          }
          try {
            const ReservationDetails = await eventsRepository.bookingChairs(payload)
            this.btnLoader = false
            localStorage.setItem('ReservationDetails',JSON.stringify(ReservationDetails))
            if (this.reservationDetails.payment_type == 'FATORA') {
              window.open(ReservationDetails.data.url,'_blank')
            } else {
              this.$router.push('/events/OTP')     
            }
          } catch (error) {
              this.btnLoader = false           
          }
        },
    },
    mounted() {
      window.addEventListener('resize', this.onResizeScroll)
      window.addEventListener('scroll', this.onResizeScroll);
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResizeScroll)
      window.removeEventListener('scroll', this.onResizeScroll);
    },
    created(){
      if (this.$route.query.success) {
        if (this.$route.query.success == 'true') {
          toasted.success('تم الحجز بنجاح');
        } else {
          toasted.failed('للاسف لم يتم الحجز');
        }
      }
      this.getEventId()
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
.events {
  font-family: 'Tajawal', sans-serif;
  max-width: 425px;
  min-height: 100vh;
  margin: 0px auto;
  padding-bottom: 50px;
}
.events .my-container{
  padding-top: 40px;
  min-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* background: url("../../../../../public/assets/images/website/events/background.png") no-repeat bottom ; */
  /* background-size: 280px auto */
}
.events .my-container .main-logo{
  max-width: 240px;
  margin-bottom: 30px;
}
.events .my-container .inputs input[type="text"],
.events .my-container .inputs input[type="number"],
.events .my-container .inputs .selector{
  font-size: 17px;
  height: 50px;
  width: 280px;
  border-radius: 5px;
  border: 1px solid #A78853;
  outline: none;
  padding: 0px 10px;
}
.events .my-container .inputs .selector{
  background-color: white;
}
.events .my-container .inputs label{
  margin-bottom:0px
}
.events .my-container .inputs p{
  color: #936C22;
  font-weight: 500;
  margin-bottom: 10px;
}
.events .my-container .content .price p{
  color: #936C22;
  width: 280px;
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 10px;
}

.dropdown{
  width: 280px;
  position: relative;
}
.dropdown p{
  margin-bottom: 0px !important;
}

.dropdown .dropdown__show{
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #A78853;
  padding: 5px 10px;
  height: 50px;
}
.dropdown .dropdown__show div{
  display: flex;
  align-items: center;
}
.dropdown .dropdown__show div img{
  width: 40px;
  border-radius: 5px;
  margin-left: 15px;
}
.dropdown .dropdown__show img{
  width: 14px;
  transition: all 0.3s linear;
}
.dropdown .dropdown__show img.rotate{
  transform: rotate(180deg);
}
.dropdown .dropdown__menu{
  background-color: #000000c2;
  /* padding: 5px 10px; */
  display: none;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  top: 50px;
  left: 0px;
  width: 100%;
  z-index:999;
}
.dropdown .dropdown__menu.show{
  display: block;
}
.dropdown .dropdown__menu div{
  display: flex;
  align-items: center;
  margin-top: 5px;
  cursor: pointer;
  transition: all 0.3s linear;
  padding: 5px 10px;
}
.dropdown .dropdown__menu div:first-child{
  margin-top: 0px;
}
.dropdown .dropdown__menu div:hover{
  background-color: #000000;
}
.dropdown .dropdown__menu img{
  width: 40px;
  border-radius: 5px;
  margin-left: 15px;
}
.dropdown .dropdown__menu p{
  color: white !important;
}
.dropdown .dropdown__menu .selected{
  background-color: #000000;
}
</style>




